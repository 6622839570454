.logo {
  margin-left: 20px;
  border-radius: 10px;
}
video {
  width: 100%;
  opacity: 0.8;
}
.bgvideo {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}
h5 {
  color: rgb(16, 16, 112);
}
#mainfooter h5 {
  color: white;
}
#responsive-navbar-nav .navLink,
#nav-dropdown {
  color: white;
  padding-left: 40px;
  padding-right: 35px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
/*Remove dropdown arrow from Navdropdown*/
.dropdown-toggle::after {
  display: none !important;
}
#responsive-navbar-nav .navLink:hover,
#nav-dropdown:hover {
  background-color: sandybrown;
  opacity: 0.8;
  border-radius: 10px;
}

.dropdownItem:hover {
  background-color: sandybrown;
}
/* To display submenu Onhover*/
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
#mainfooter ul > li {
  margin-top: 1em;
  list-style-type: square;
}
#mainfooter {
  background-color: #4d5053;
  color: white;
}

#bottomnav {
  background-color: #343a40;
  color: white;
  text-align: center;
}
#mainfooter img {
  margin-top: 2.3em;
  margin-left: 0;
}
#mainfooter h5,
#mainfooter span {
  margin-top: 1em;
}
#bottomnav span {
  text-align: center;
}
ul {
  list-style-type: none;
}
.card ul {
  line-height: 1.8;
  font-size: 14px;
}

.card li::before,
#products-content li::before {
  content: "✓";
  margin-left: -2em;
  margin-right: 1em;
  color: #2b9bc0;
  font-size: 15px;
}
/* Services */
.card li,
#products-content li {
  font-size: 15px;
}
.card:hover {
  transform: scale(1.01);
}
.card {
  border-color: #4dbfe6;
  height: 100%;
}
#dtblock img,
#sodarblock img {
  margin-left: auto;
  margin-right: auto;
}
#dtblock,
#sodarblock,
#swblock,
#products-content2 {
  text-align: justify;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

#about,
#products-content {
  text-align: justify;
  line-height: 1.8;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: darkblue;
}

.card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#products-content2 {
  line-height: 1.8;
}
#swblock img {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}
#dtblock li,
#swblock li {
  text-align: justify;
  font-size: 14.5px;
  line-height: 1.8;
}
.intro-header {
  padding-top: 50px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
  padding-bottom: 50px;
  text-align: right;
  color: #f8f8f8;
  background: url(software.jpg) no-repeat center center;
  background-size: cover;
  /* opacity: 0.9;*/
}
.intro-message {
  position: relative;
  padding-top: 20%;
  padding-bottom: 20%;
}

.intro-message > h1 {
  margin: 0;
  font-size: 2em;
}
#frontend img,
#backend img,
#db img,
#testing img {
  width: 95px;
  height: 75px;
}
#signin {
  margin: 0 auto;
  max-width: 450px;
  padding-left: 30px;
  padding-right: 30px;
}
#singin-form {
  padding-left: 30px;
  padding-right: 30px;
}
#text {
  position: absolute;
  top: 25%;
  left: 50%;
  font-size: 5vw;
  color: white;
  text-align: center;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.key-technology img {
  border-radius: 15px;
}
/* If the screen size is 601px wide or more, set the font-size of <div> to 80px
 @media screen and (min-width: 601px) {
  #products-content{
    font-size:15px;
  }
} */
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px 
@media screen and (max-width: 600px) {
  #products-content {
    font-size: 80px;
  }
}*/

.whatsapp svg {
  border-radius: 5px;
  background-color: green;
  width: 35px;
}

.linkedin svg {
  background-color: blue;
}
.linkedin a,
.whatsapp a {
  color: white;
  text-decoration: none;
}
.linkedin a:hover,
.whatsapp a:hover {
  color: white;
  background-color: rgb(250, 125, 41);
  border-radius: 10px;
}

/* Job post styling starts */
.job-post h1 {
  color: rgb(250, 125, 41);
}

.job-post p {
  color: darkblue;
  text-align: justify;
  line-height: 1.9;
}
.job-post h3 {
  color: rgb(250, 125, 41);
}
.job-post ul > li {
  margin-top: 1em;
  list-style-type: square;
  color: darkblue;
}
.job-post p,
ul,
li {
  font-size: 16px; /* Adjust the font size as needed */
}
/* Job post styling ends */
.card-title,
.card-body h4 {
  color: rgb(250, 125, 41);
}
.card-body,
.card-text {
  color: darkblue;
}
