body {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Ensures the body and root occupy full height */
body, html, #root {
  height: 100%;
  margin: 0;
}
/* Page container setup */
#page-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Content wrapper grows to fill remaining space */
#content-wrap {
  flex: 1;
}