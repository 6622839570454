.floating-icons1 {
    position: fixed;
    right: 0;
    top: 38%;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Maintain spacing between icons */
  }
  .floating-icons2 {
    position: fixed;
    right: 0;
    top: 45%;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Maintain spacing between icons */
  }
  .floating-icons3 {
    position: fixed;
    right: 0;
    top: 52%;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Maintain spacing between icons */
  }
  .floating-icons1 a {
    position: relative;
    display: flex;
    align-items: center;
    width: 45px; /* Default width for icons */
    height: 45px;
    background: white;
    border-radius: 5px 0 0 5px;
    text-decoration: none;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  .floating-icons2 a {
    position: relative;
    display: flex;
    align-items: center;
    width: 45px; /* Default width for icons */
    height: 45px;
    background: white;
    border-radius: 5px 0 0 5px;
    text-decoration: none;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  .floating-icons3 a {
    position: relative;
    display: flex;
    align-items: center;
    width: 45px; /* Default width for icons */
    height: 45px;
    background: white;
    border-radius: 5px 0 0 5px;
    text-decoration: none;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  .floating-icons1 i {
    min-width: 45px; /* Icon width */
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .floating-icons2 i {
    min-width: 45px; /* Icon width */
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .floating-icons3 i {
    min-width: 45px; /* Icon width */
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .floating-icons1 span {
    margin-left: 10px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    width: 0; /* Initially collapsed */
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth expand */
  }
   .floating-icons2 span {
    margin-left: 10px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    width: 0; /* Initially collapsed */
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth expand */
  }
  .floating-icons3 span {
    margin-left: 10px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    width: 0; /* Initially collapsed */
    overflow: hidden;
    transition: all 0.3s ease; /* Smooth expand */
  }
  
  /* Hover Effect */
  .floating-icons1 a:hover {
    width: 150px; /* Icon expands when hovered */
    
  }
  .floating-icons2 a:hover {
    width: 150px; /* Icon expands when hovered */
  }
  .floating-icons3 a:hover {
    width: 180px; /* Icon expands when hovered */
  }
  .floating-icons1 a:hover span {
    opacity: 1; /* Text becomes visible */
    width: auto; /* Text expands naturally */
  }
  .floating-icons2 a:hover span {
    opacity: 1; /* Text becomes visible */
    width: auto; /* Text expands naturally */
  }
  .floating-icons3 a:hover span {
    opacity: 1; /* Text becomes visible */
    width: auto; /* Text expands naturally */
  }
  /* Icon colors */
  .floating-icons1 .icon-whatsapp i {
    color: #25d366; /* WhatsApp green */
  }
  
  .floating-icons2 .icon-linkedin i {
    color: #0077b5; /* LinkedIn blue */
  }
  
  .floating-icons3 .icon-email i {
    color: #0a66c2; /* Email blue */
  }
  
  /* Hover background */
  .floating-icons1 a:hover, .floating-icons2 a:hover, .floating-icons3 a:hover {
    background: #f8f9fa;
  }
  .floating-icons1 a:hover{
    background: #f8f9fa;
  }
  